import React from 'react';
import Layout from '../components/layout';
import ProjectCard from '../components/project-card';

const CaseStudies = () => (
    <Layout>
        <section className="columns is-centered is-multiline is-gapless">
            <div className="column is-10">
                <div className="page-title-wrap margin-bot-3">
                    <span className="title-decoratiion"></span>
                    <h1 className="general-page-title">Case Studies</h1>
                </div>
                <ProjectCard />
            </div>
        </section>
    </Layout >
);

export default CaseStudies;

