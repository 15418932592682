import React from 'react';
import './style.scss';
import { StaticQuery, graphql, Link } from "gatsby"
import volantis from '../images/volantis-logo.svg';
import gredu from '../images/gredu-logo-white.svg';
import visionplusLogo from '../images/visionplus-logo.svg';
import Img from 'gatsby-image';

const ProjectCard = () => (
    <StaticQuery
        query={graphql`
            query projectThumbnailQuery {
                greduDS: file(relativePath: { eq: "gredu-ds-page.png" }) {
                    childImageSharp {
                        fluid(quality: 80) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
                volantisDS: file(relativePath: { eq: "volantis-ds-page.png" }) {
                    childImageSharp {
                        fluid(quality: 80) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
                hrisMarkplus: file(relativePath: { eq: "hris-markplus.png" }) {
                    childImageSharp {
                        fluid(quality: 80) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
                studentAppCourse: file(relativePath: { eq: "student-app-course-page.png" }) {
                    childImageSharp {
                        fluid(quality: 90) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
                
                visionPlusUT: file(relativePath: { eq: "visionplus-ut-cover.png" }) {
                    childImageSharp {
                        fluid(quality: 90) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
                
                designWorkflowCover: file(relativePath: { eq: "design-workflow-cover.png" }) {
                    childImageSharp {
                        fluid(quality: 90) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
                
            }
    `}
        render={(data) => (
            <section>
                <div className="project-wrapper">
                    <div className="columns is-gapless">
                        <div className="column is-6 is-pulled-right">
                            <img className="logo-size-b" src={visionplusLogo} alt="company-logo" />
                            <Link to="visionplus-mobile-ut">
                                <Img fluid={data.visionPlusUT.childImageSharp.fluid} alt="Project Thumbnail" className="project-thumbnail" />
                            </Link>
                        </div>
                        <div className="column is-6">
                            <p className="year">2020</p>
                            <Link to="visionplus-mobile-ut"><h2 className="title">Vision+ Mobile App<br />Remote UT During Pandemic</h2></Link>
                            <p className="excerpt">Uncovering design inconsistencies and usability issues in order to get the buy-in from stakeholders to improve the app. </p>
                            <Link to="visionplus-mobile-ut" className="view-case-btn">View Case Study</Link>
                        </div>
                    </div>
                </div>
                <div className="project-wrapper">
                    <div className="columns is-gapless">
                        <div className="column is-6 is-pulled-right">
                            <img className="logo-size-b" src={visionplusLogo} alt="company-logo" />
                            <Link to="visionplus-design-workflow">
                                <Img fluid={data.designWorkflowCover.childImageSharp.fluid} alt="Project Thumbnail" className="project-thumbnail" />
                            </Link>
                        </div>
                        <div className="column is-6">
                            <p className="year">2020</p>
                            <Link to="visionplus-design-workflow"><h2 className="title">Define Design Workflow that Nurtures User-centered Design</h2></Link>
                            <p className="excerpt">Bring everyone on the same page, improve cross-division workflow, crafting speed, and overall design team performance, etc.</p>
                            <Link to="visionplus-design-workflow" className="view-case-btn">View Case Study</Link>
                        </div>
                    </div>
                </div>
                <div className="project-wrapper">
                    <div className="columns is-gapless">
                        <div className="column is-6 is-pulled-right">
                            <img className="logo-size-a" src={gredu} alt="company-logo" />
                            <Link to="gredu-design-system-v0.1">
                                <Img fluid={data.greduDS.childImageSharp.fluid} alt="Project Thumbnail" className="project-thumbnail" />
                            </Link>
                        </div>
                        <div className="column is-6">
                            <p className="year">2020</p>
                            <Link to="gredu-design-system-v0.1"><h2 className="title">GREDU<br />DESIGN SYSTEM V0.1</h2></Link>
                            <p className="excerpt">A design system starter that will cover more than 5 products in the school ecosystem (web, iOS, & Android).</p>
                            <Link to="gredu-design-system-v0.1" className="view-case-btn">View Case Study</Link>
                        </div>
                    </div>
                </div>
                <div className="project-wrapper">
                    <div className="columns is-gapless">
                        <div className="column is-6 is-pulled-right">
                            <img className="logo-size-b" src={volantis} alt="company-logo" />
                            <Link to="volantis-design-system">
                                <Img fluid={data.volantisDS.childImageSharp.fluid} alt="Project Thumbnail" className="project-thumbnail" />
                            </Link>
                        </div>
                        <div className="column is-6">
                            <p className="year">2019</p>
                            <Link to="volantis-design-system"><h2 className="title">VOLANTIS<br />DESIGN SYSTEM</h2></Link>
                            <p className="excerpt">The design language that speaks for B2B startup with a modern approach. Covering foundation, UI Kit (for designer & engineer), design assets central.</p>
                            <Link to="volantis-design-system" className="view-case-btn">View Case Study</Link>
                        </div>
                    </div>
                </div>
                <div className="project-wrapper">
                    <div className="columns is-gapless">
                        <div className="column is-6 is-pulled-right">
                            <img className="logo-size-b" src={volantis} alt="company-logo" />
                            <Link to="hris-markplus">
                                <Img fluid={data.hrisMarkplus.childImageSharp.fluid} alt="Project Thumbnail" className="project-thumbnail" />
                            </Link>
                        </div>
                        <div className="column is-6">
                            <p className="year">2018</p>
                            <Link to="hris-markplus"><h2 className="title">HRIS <br />MarkPlus, Inc.</h2></Link>
                            <p className="excerpt">Human resource information system that covers attendance, leave, benefits, employees information, etc.</p>
                            <Link to="hris-markplus" className="view-case-btn">View Case Study</Link>
                        </div>
                    </div>
                </div>
                <div className="project-wrapper">
                    <div className="columns is-gapless">
                        <div className="column is-6 is-pulled-right">
                            <img className="logo-size-b" src={gredu} alt="company-logo" />
                            <Link to="student-app-course">
                                <Img fluid={data.studentAppCourse.childImageSharp.fluid} alt="Project Thumbnail" className="project-thumbnail" />
                            </Link>
                        </div>
                        <div className="column is-6">
                            <p className="year">2020</p>
                            <Link to="student-app-course"><h2 className="title">Student App - Course</h2></Link>
                            <p className="excerpt">Adding a course platform with a subscription model into an existing student app.</p>
                            <Link to="student-app-course" className="view-case-btn">View Case Study</Link>
                        </div>
                    </div>
                </div>
            </section>
        )}
    />
)

export default ProjectCard

